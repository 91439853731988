import { useIntl, Link } from "gatsby-plugin-intl"
import { dateFormat, defaultIntl } from "../../utils"
import Layout from "../../components/layout"
import React, { useEffect, useState } from "react"
import "../../includes/news/news.sass"
import { $get } from "../../components/forms"

const initData = []
export default () => {
  const intl = useIntl() || defaultIntl
  const [list, setList] = useState(initData)
  const [isLoading, setIsLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [showItem] = useState(12)
  const [lang] = useState(intl.locale)
  const [page, setPage] = useState(Math.ceil(list.length / showItem))
  const [current, setCurrent] = useState(1)

  const QueryOption = () => `_start=${page}&_sort=created_at:DESC`
  const loadMore = () => {
    setIsLoading(true)
  }
  const handleLoad = () => {
    $get(`/news-posts?${QueryOption()}`)
      .then(({ data }) => {
        if (data && data.length) {
          setList([...list, ...data])
          setCurrent(current + 1)
        }
        if (data.length < showItem) {
          setFinished(true)
        }
      })
      .catch(e => {
        alert(e.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    handleLoad()
  }, [])

  useEffect(() => {
    if (isLoading) {
      handleLoad()
    }
  }, [isLoading])

  useEffect(() => {
    setPage((current - 1) * showItem)
  }, [current])

  return (
    <Layout intl={intl} page="news">
      <div className="news section">
        <div className="container">
          <div className="section-title">{intl.formatMessage({ id: "news.title" })}</div>
          <div className="section-subtitle">{intl.formatMessage({ id: "news.desc" })}</div>
          <div className="news-list">
            {list.filter(item => {
              const title = intl.locale === 'zh' ? 'title' : `title_${intl.locale}`;
              return !!item[title]
            }).map((item, index) => (
              <Link className="card-item" key={index} to={`/news/detail?news_id=${item.id}`}>
                <Card item={item} intl={intl} />
              </Link>
            ))}
          </div>
          {!finished && (
            <div className="load-more">
              <div className="btn-wrapper">
                {isLoading ? (
                  <button className="more-btn">{intl.formatMessage({ id: "global.loading" })}</button>
                ) : (
                  <button className="more-btn" onClick={loadMore}>
                    {intl.formatMessage({ id: "global.viewmore" })}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

const Card = ({ item, intl, onClick = () => {} }) => {
  return (
    <div className="card" onClick={onClick}>
      <div className="card-image">
        <figure className="image">
          <img src={item.cover} alt="Placeholder image" />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <div className="title is-4">{intl.locale === "zh" ? item.title : item[`title_${intl.locale}`]}</div>
            <p className="time">{dateFormat(item.date || item.created_at)}</p>
          </div>
        </div>
        <div className="content">{intl.locale === "zh" ? item.summary : item[`summary_${intl.locale}`]}</div>
      </div>
    </div>
  )
}
